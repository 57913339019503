import React, { useEffect } from 'react';

import {
    VStack,
    Flex,
    Image,
    Spacer,
    Button,
    Divider,
    HStack,
    Avatar,
    Text
} from '@chakra-ui/react';

import {
    VscCheck,
    VscNote,
    VscOrganization,
    VscMirror,
    VscFeedback,
    VscSearch
} from "react-icons/vsc";

import SideBarButton from './SideBarButton';

import Const from './Constants';

import { handleSubscribe } from './Utils';

const SideBar = ({ auth, appMode, setAppMode, setIsSignedIn, subscription }) => {
    useEffect(() => {
        // Manually initialize Lemon.js after the component has mounted
        window.createLemonSqueezy();
    }, []);

  return (
    <VStack
        width={'240px'}
        minW={'240px'}
        maxW={'240px'}
        background={Const.Gray46}
        padding={'10px'}
        h={'100vh'}
        spacing={'8px'}
        borderRight={'1px solid'}
        borderColor={Const.Gray64}
    >
        <HStack
            w={'100%'}
            paddingLeft={2}
            style={{ cursor: "pointer" }}
            onClick={() => {
                setAppMode(Const.sProfile);
            }}
        >
            <Avatar
                src={(auth && auth.currentUser) ? auth.currentUser.photoURL : ''}
                size='sm'
                borderWidth={2}
            />
            <Text
                fontSize={14}
                fontWeight={'bold'}
                color={Const.White}
                marginLeft={1}
            >
                {auth && auth.currentUser && auth.currentUser.displayName}
            </Text>
        </HStack>
        <Divider borderColor={Const.Gray100} />
        <SideBarButton
            current={appMode === Const.sTasks}
            icon={<VscCheck/>}
            title={Const.sTasks}
            action={() => setAppMode(Const.sTasks)}
        />
        <SideBarButton
            current={appMode === Const.sNotes}
            icon={<VscNote/>}
            title={Const.sNotes}
            action={() => setAppMode(Const.sNotes)}
        />
        <SideBarButton
            current={appMode === Const.sMeetings}
            icon={<VscOrganization/>}
            title={Const.sMeetings}
            action={() => setAppMode(Const.sMeetings)}
        />
        <SideBarButton
            current={appMode === Const.s1on1Meetings}
            icon={<VscMirror/>}
            title={Const.s1on1Meetings}
            action={() => setAppMode(Const.s1on1Meetings)}
        />
        <SideBarButton
            current={appMode === Const.sInterviews}
            icon={<VscSearch/>}
            title={Const.sInterviews}
            action={() => setAppMode(Const.sInterviews)}
        />
        <Spacer/>
        <SideBarButton
            current={appMode === Const.sFeedback}
            icon={<VscFeedback/>}
            title={Const.sFeedback}
            action={() => setAppMode(Const.sFeedback)}
        />
        <Divider my={1} borderColor={Const.Gray100} />
        <HStack>
            <Flex
                align={Const.sCenter}
                justify={Const.sCenter}>
                    <Image src='logo-no-bg-wide.png' height={'32px'} marginRight={'4px'} />
            </Flex>
            { subscription.state === false &&
                <Button
                    size={'sm'}
                    height={'30px'}
                    background={Const.ActiveColor}
                    color={Const.White}
                    _hover={{ background: Const.DoneLightColor }}
                    fontSize={14}
                    onClick={
                        () => { handleSubscribe(auth.currentUser.uid);        
                    }}
                >
                    Subscribe
                </Button>
            }
        </HStack>
    </VStack>
  );
};

export default SideBar;
