import {
    Button,
    Box
} from '@chakra-ui/react';

import Const from './Constants';

const SideBarButton = ({current, icon, title, action}) => {
    return (
        <Button
            leftIcon={
                <Box color={current === true ? Const.Orange400 : Const.White}>
                    {icon}
                </Box>
            }
            size={'sm'}
            height={'30px'}
            width={'100%'}
            background={ current === true ? Const.Gray69 : Const.Gray46 }
            color={current === true ? Const.Orange400 : Const.White}
            justifyContent={'flex-start'}
            _hover={{ background: Const.Gray69 }}
            onClick={action}
        >
            <Box
                as={'span'}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                maxW={'100%'} // Ensure the text respects the button's width
                >
                {title}
            </Box>
        </Button>
    );
};

export default SideBarButton;
