import React from 'react';
import {
    Flex,
    Button
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

import Const from './Constants';

const TaskTopBar = ({ appMode, showCompletedTasks, setShowCompletedTasks }) => {    
    return (
        <Flex
            justifyContent={'left'}
            alignItems={Const.sCenter}
            padding={'10px'}
            background={Const.Gray46}
            width={'100%'}
            borderBottom={'1px solid'}
            borderColor={Const.Gray64}
        >
            { appMode === Const.sTasks &&
            <Button
                leftIcon={<CheckIcon/>}
                size={'sm'}
                height={'30px'}
                background={ showCompletedTasks ? Const.DoneColor : Const.Gray36}
                color={Const.Gray165}
                _hover={{ background: showCompletedTasks ? Const.DoneLightColor : Const.Gray69 }}
                fontSize={12}
                onClick={() => {
                    setShowCompletedTasks(!showCompletedTasks);
                }}>
                Show completed
            </Button>
            }
        </Flex>
    );
};

export default TaskTopBar;
