import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    Text,
    VStack
} from '@chakra-ui/react';

// import { DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import NotesBar from './NotesBar';

import NoteDetails from './NoteDetails';

const Notes = ({ auth, notesData, setNotesData }) => {
    const [currentNote, setCurrentNote] = useState(null);

    useEffect(() => {
        loadNotesData();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      // Lazy loading of the body of the note
      if(currentNote && !currentNote.body) {
          loadNotesDataById(currentNote.id);
      }
    // eslint-disable-next-line
    }, [currentNote]);

    async function loadNotesData() {
        try {
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + '/notes';
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const notes = await response.json();
          setNotesData(notes);

          if(!notes.empty) {
            // setCurrentNote(notes[0]);
          }
        } catch (error) {
          console.error(error.message);
        }
      }

      async function loadNotesDataById(id) {
        try {
          const queryParams = new URLSearchParams({
            id
            });
          const idToken = await auth.currentUser.getIdToken();
          const theURL = `${process.env.REACT_APP_BASE_URL}/notes?${queryParams}`;
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const note = await response.json();

          const copyNotesData = JSON.parse(JSON.stringify(notesData));

          for(let i = 0; i < copyNotesData.length; ++i) {
            // Update the body
            if(copyNotesData[i].id === id ) {
                copyNotesData[i].title = note.title;
                copyNotesData[i].body = note.body;
            }

            // Update current note so we get the body displayed in details
            if(currentNote.id === copyNotesData[i].id &&
              currentNote.body !== copyNotesData[i].body) {
              let copyCurrentNote = JSON.parse(JSON.stringify(currentNote));
              copyCurrentNote.body = copyNotesData[i].body;
              setCurrentNote(copyCurrentNote);
            }
          }

          setNotesData(copyNotesData);

        } catch (error) {
          console.error(error.message);
        }
      }

    return (
        <Box
            display={'flex'}
            justifyContent={Const.sCenter}
            alignItems={Const.sCenter}
            height={'100vh'}
            width={'100vw'}
        >
            <Flex
              w={'100%'}
              alignItems={currentNote ? 'stretch' : Const.sCenter}
            >
                <NotesBar
                  auth={auth}
                  notesData={notesData}
                  setNotesData={setNotesData}
                  currentNote={currentNote}
                  setCurrentNote={setCurrentNote}
                  loadNotesData={loadNotesData}
                />
                { currentNote ? (
                <NoteDetails
                  auth={auth}
                  notesData={notesData}
                  setNotesData={setNotesData}
                  currentNote={currentNote}
                  setCurrentNote={setCurrentNote}
                  loadNotesData={loadNotesData}
                />) : (
                  <VStack 
                      w={'100%'}
                  >
                  <Text
                      fontSize={14}
                      fontWeight={'bold'}
                      color={Const.Gray100}
                  >
                      Select a note or create a new one
                  </Text>
                </VStack>
                )
                }
            </Flex>
        </Box>
    );
};

export default Notes;
