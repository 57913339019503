import React from 'react';
import {
    VStack,
    Button,
    Avatar,
    Text,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure
} from '@chakra-ui/react';

import Const from './Constants';

import { actualSizePhotoUrl, handleSubscribe, showSuccessToast } from './Utils';

const Profile = ({ auth, setAppMode, setIsSignedIn, subscription, setSubscription, toast }) => {
    // eslint-disable-next-line
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    // Sign out logic
    async function handleClickSignOut() {
        await auth.signOut();
        await setIsSignedIn(false);
        await setAppMode(Const.sSignIn);
    }

    async function handleCancelSubscription() {
        try {
            const idToken = await auth.currentUser.getIdToken();
            const theURL = process.env.REACT_APP_BASE_URL + '/subscriptions/cancel';
            const response = await fetch(theURL, {
              method: Const.HttpPost,
              headers: new Headers({
                Authorization: idToken,
                Accept: Const.AppJson,
                [Const.ContentType]: Const.AppJson
              })
            });

            // Check if the request was successful
            if(response.ok) {
                setSubscription({
                    state: false,
                    subscriptionId: null, // unused in front end for now
                    timestamp: null // unused in front end for now
                });

                showSuccessToast(toast, 'FlowHero Pro', 'Subscription is canceled', 'warning');
            }
            else {
                showSuccessToast(toast, 'FlowHero Pro', 'Failed to cancel the subscription', 'error');
            }
          } catch (error) {
            console.error(error.message);
          }

          // Close the unsubscribe alert dialog
          onClose();
    }

    return (
        <>
        <VStack padding={6} w={'100%'} alignItems={Const.sCenter}>
            <Avatar
                src={(auth && auth.currentUser) ? actualSizePhotoUrl(auth.currentUser.photoURL) : ''}
                size='xl'
                borderWidth={2}
            />
            <Text
                fontSize={20}
                fontWeight={'bold'}
                color={Const.White}
                marginLeft={1}
            >
                {auth && auth.currentUser && auth.currentUser.displayName}
            </Text>
            <Text
                fontSize={12}
                fontWeight={'bold'}
                color={Const.Gray180}
                marginLeft={1}
            >
                {auth && auth.currentUser && auth.currentUser.email}
            </Text>
            <HStack>
                <Button
                    size={'sm'}
                    height={'30px'}
                    background={ subscription.state === false ? Const.ActiveColor : Const.Gray36 }
                    color={ subscription.state === false ? Const.White : Const.Gray165 }
                    _hover={{
                        background: subscription.state === false ? Const.DoneLightColor : Const.Red600
                    }}
                    fontSize={subscription.state === false ? 14 : 12}
                    onClick={() => {
                        if(subscription.state === false) {
                            handleSubscribe(auth.currentUser.uid);
                        }
                        else {
                            // Open cancel subscription dialog
                            onOpen();
                        }
                    }}
                >
                    { subscription.state === false ? 'Subscribe' : 'Cancel Pro' }
                </Button>
                <Button
                    size={'sm'}
                    height={'30px'}
                    fontSize={12}
                    background={Const.Gray36}
                    color={Const.Gray165}
                    _hover={{ background: Const.Red600 }}
                    onClick={handleClickSignOut}
                    marginLeft={2}
                >
                    Sign out
                </Button>
            </HStack>
        </VStack>

        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay
                backdropFilter="blur(2px)"
                bg="rgba(0, 0, 0, 0.6)"
            >
            <AlertDialogContent
                bg={Const.Gray31}
                color={Const.White}
            >
                <AlertDialogHeader fontSize='lg'>
                    Cancel subscription
                </AlertDialogHeader>

                <AlertDialogBody>
                    Are you sure? You will loose access to all Pro features.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose} size={'sm'}>
                    Close
                </Button>
                <Button colorScheme='red' onClick={handleCancelSubscription} ml={3} size={'sm'}>
                    Cancel subscription
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </>    
    );
};

export default Profile;
