import React, { useState, useEffect, useRef } from 'react';
import {
    HStack,
    Input,
    VStack,
    Select,
    Textarea,
    Button
} from '@chakra-ui/react';

import Const from './Constants';

import { showSuccessToast } from './Utils';

const Feedback = ({ auth, toast }) => {
    

    const [title, setTitle] = useState('');
    const titleInputRef = useRef(null);

    const [body, setBody] = useState('');

    // Initialize state for the selected value
    const [selectedOption, setSelectedOption] = useState(Const.sFeature);

    useEffect(() => {
        // If the title is empty set the focus on title input
        if(!title && titleInputRef.current) {
            titleInputRef.current.focus();
        }
    }, [title]);

    // Create a handler function to update the state
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleAddFeedback = async () => {
        try {
          const idToken = await auth.currentUser.getIdToken();
          const theURL = process.env.REACT_APP_BASE_URL + Const.sFeedbacksPath;
          const response = await fetch(theURL, {
            method: Const.HttpPut,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify({
                type: selectedOption,
                title: title,
                body: body
              })
          });
    
          // Check if the request was successful
          if(!response.ok) {
            throw new Error('Failed to add feedback');
          }

          // Show the toast notification
          showSuccessToast(toast, 'Feedback Submitted', 'Thank you for the feedback!');

          // Reset controls
          setTitle('');
          setBody('');
          setSelectedOption(Const.sFeature);

        } catch (error) {
          console.error(error.message);
        }
      };

    return (
        <VStack padding={4} w={'100%'} alignItems={'left'}>
            <HStack paddingLeft={4}>
                <Input
                    ref={titleInputRef}
                    fontSize={22}
                    fontWeight={'bold'}
                    value={title}
                    border={Const.sNone}
                    marginLeft={-4}
                    placeholder={'Enter feedback title'}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={(event) => {
                        // handleBlurTitle(event);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === Const.sEnter) {
                            // Remove focus
                            titleInputRef.current.blur();
                            event.preventDefault(); // Prevents any default action
                        }
                    }}
                />
                <Select
                    maxW={'180px'}
                    color={Const.Gray180}
                    borderColor={Const.Gray180}
                    value={selectedOption}
                    onChange={handleSelectChange}
                >
                    <option value={Const.sFeature}>Feature request</option>
                    <option value='bug'>Bug report</option>
                    <option value='other'>Other</option>
                </Select>
                <Button
                    background={'#276749'}
                    color={Const.White}
                    _hover={{ background: '#38A169' }}
                    fontSize={14}
                    paddingLeft={6}
                    paddingRight={6}
                    isDisabled={!title.trim() && !body.trim()}
                    onClick={() => {
                        handleAddFeedback();
                    }}>
                    Submit feedback
                </Button>
            </HStack>
            <Textarea
                value={body}
                fontSize={16}
                flex={'1'} // Allow Textarea to grow and take up remaining space
                resize={Const.sNone}
                borderColor={Const.Gray100}
                placeholder={'Enter feedback here to help us improve the product'}
                _placeholder={{ color: Const.Gray100 }}
                _hover={{
                    cursor: 'text'
                }}
                onChange={(event) => {
                    setBody(event.target.value);
                }}
            />
        </VStack>
    );
};

export default Feedback;
