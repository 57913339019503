// import { Timestamp } from 'firebase/firestore';

import Const from './Constants';

export const notifUserSignIn = async (auth, setSubscription) => {
    try {
        const idToken = await auth.currentUser.getIdToken(true); // True to refresh token on sigh in
        const theURL = process.env.REACT_APP_BASE_URL + '/signin';
        const response = await fetch(theURL, {
        headers: new Headers({
            Authorization: idToken
        })
        });
        const sub = await response.json();

        setSubscription({
          state: sub.state,
          subscriptionId: sub.subscriptionId, // unused in front end for now
          timestamp: sub.timestamp // unused in front end for now
        });

    } catch (error) {
        console.error(error.message);
    }
}

export const handleAuthStateChangeCallback = async (auth, isSignedIn, setIsSignedIn, onSignIn, setSubscription) => {
    // This should be always false it seems
    if(!isSignedIn) {
      const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
        setIsSignedIn(!!user);

        // Notify caller on sign in results
        onSignIn(!!user);

        // Notify backend that user has signed in.
        notifUserSignIn(auth, setSubscription);
      });
      return () => { unregisterAuthObserver(); } // Make sure we un-register Firebase observers when the component unmounts.
    } else {
        // Notify caller on sign in results
        onSignIn(false);
    }
  }

export const handleDeleteTask = async (auth, taskId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const theURL = process.env.REACT_APP_BASE_URL + '/tasks';
      await fetch(theURL, {
        method: Const.HttpDelete,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          taskId
        })
      });
    } catch (error) {
      console.error(error.message);
    }
}

export const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const  fsDateToString = (date) => {
  // Convert Firestore timestamp to JavaScript Date object
  const d = new Date(date._seconds * 1000); // Multiply seconds by 1000 to convert to milliseconds

  // Now format the date as you wish, for example using `toLocaleDateString`
  const formattedDate = d.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return formattedDate;
}

export const getTodayFSDate = () => {
  const now = new Date();  // Get the current date and time

  const seconds = Math.floor(now.getTime() / 1000);  // Convert the current time to seconds
  const nanoseconds = (now.getTime() % 1000) * 1000000;  // Calculate the remaining nanoseconds

  // Create a Firestore Timestamp object in their format
  return {_seconds: seconds, _nanoseconds: nanoseconds};  
}

export const sameDay = (date1, date2) => {
  // Convert Firestore-like Timestamps to JavaScript Date objects
  const d1 = new Date(date1._seconds * 1000);
  const d2 = new Date(date2._seconds * 1000);

  // Compare the year, month, and day of both dates
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const sortByTitle = async (array) => {
  array.sort((a, b) => {
    if (!a || !a.title) return 1;  // Treat null/undefined as "greater" to push them to the end
    if (!b || !b.title) return -1; // Same logic for `b`
    
    return a.title.localeCompare(b.title);
  });
}

export const showSuccessToast = (toast, title, description, status) => {
  toast({
    title,
    description,
    status: status === undefined ? 'success' : status,
    duration: 3000,     // Duration in milliseconds
    isClosable: true,   // Show close button
    position: 'top'
  });
}

export const actualSizePhotoUrl = (url) => {
  // Find the last occurrence of the '=' character
  const index = url.lastIndexOf('=');
  
  // If '=' is found, truncate the URL up to that point, otherwise return the original URL
  return index !== -1 ? url.substring(0, index) : url;
}

export const handleSubscribe = (userId) => {
  const checkoutURL = `https://flowhero.lemonsqueezy.com/buy/889a6ac3-1dc4-42ae-8765-a164bf7f9845?discount=0&checkout[custom][user_id]=${userId}`;
  window.location.href = checkoutURL;
}
